import accountServices from '../../../services/account'
import { resolve } from 'core-js/fn/promise'
import { Auth } from 'aws-amplify';
export default {
    data () {
      return {    
        email: ''
      }
    },
    created() {
     console.clear();
    },
    methods: {
      async onSubmitLogin(){
        const isValid = await this.$refs['observer'].validate()
        if (!isValid) {
          this._showToast('Please check your information', {variant: 'warning'})
        } else {
          Auth.forgotPassword(this.email)
              .then(data => {
               console.clear();
                //console.log(data)
              this.$router.push({name: 'SuccessPage', params: {
                title: 'Sent successfully!',
                subTitle: 'Kindly check your email for further action',
                icon:'far fa-check-circle'
              }})      
              }
                )
              .catch(err =>
                {
                 console.clear();
                  //debugger
                    if(err.message == "Username/client id combination not found."){
                      this._showToast('User not found', { variant: 'warning' })
                    }
                    else if(err.message == "Cannot reset password for the user as there is no registered/verified email or phone_number"){
                      this._showResend('', { title: 'Account Verification Pending', variant: '' }).then(confirm => {
                        if (confirm) {
                          //debugger
                           Auth.resendSignUp(this.email);
                          console.clear();
                           this._showToast('Confirmation email send successfully, please check your mailbox', { variant: 'warning' })
                        }
                    })
                      //this._showToast('User not found', { variant: 'warning' })
                    }
                    else
                    {
                      this._showToast(err.message, { variant: 'warning' })
                    }
                }
                );
        }
      },
    }
  }